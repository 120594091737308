import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import createLogger from 'debug';
import {
  TCreateReportPayloadV2,
  TCreateReportPayloadV3,
  TReport,
} from '@payler/api/merchant-cabinet';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { useDropReportsCache, useUpdateReportInCache } from './cache';
import { useReportsQueryPagerProps } from './queries';
import { useUIDateFormat } from '../use-ui-dateFormat';
import { useApi } from '../../context/ApiContextProvider';

const log = createLogger('hooks:reports');

/**
 * ### Удалить отчёт.
 * - Если удаляется отчёт на последней странице и количество отчётов на ней
 * равно 1 - перейти на предыдущую страницу.
 * - В пропсах пейджера страницы начинаются с 0
 */
export const useDeleteReportMutation = () => {
  const api = useApi();
  const dropCache = useDropReportsCache();
  const paginationProps = useReportsQueryPagerProps();

  const onSuccess = useCallback(() => {
    const { goToPage } = paginationProps;
    const lastPage = Math.floor(
      paginationProps.totalRecords / paginationProps.pageSize,
    );

    const rowsOnLastPage =
      paginationProps.totalRecords % paginationProps.pageSize;

    if (
      paginationProps.currentPage !== 0 &&
      paginationProps.currentPage === lastPage &&
      rowsOnLastPage === 1
    ) {
      goToPage(lastPage - 1);
    }
    dropCache();
  }, [dropCache, paginationProps]);

  return useMutation((id: number) => api.deleteReport(id), {
    onSuccess,
  });
};

/**
 * Создать отчёт (так же сбрасывается кэш отчётов) для RU сегмента
 */
export const useCreateReportForRuSegmentMutation = () => {
  const api = useApi();
  const dateFormat = useUIDateFormat();
  const { goToPage } = usePagerUrlCallbacks();
  const dropCache = useDropReportsCache();

  const onSuccess = useCallback(() => {
    goToPage(0);
    dropCache();
  }, [dropCache, goToPage]);

  return useMutation(
    (data: TCreateReportPayloadV2) => api.createReportV2(data, dateFormat),
    {
      onSuccess,
    },
  );
};

/**
 * Создать отчёт (так же сбрасывается кэш отчётов) для Global сегмента
 */
export const useCreateReportForGlobalSegmentMutation = () => {
  const api = useApi();
  const dateFormat = useUIDateFormat();
  const { goToPage } = usePagerUrlCallbacks();
  const dropCache = useDropReportsCache();

  const onSuccess = useCallback(() => {
    goToPage(0);
    dropCache();
  }, [dropCache, goToPage]);

  return useMutation(
    (data: TCreateReportPayloadV3) => api.createReportV3(data, dateFormat),
    {
      onSuccess,
    },
  );
};

/**
 * Переименовать отчёт
 */
export const useUpdateReportNameMutation = () => {
  const updateReport = useUpdateReportInCache();
  const api = useApi();
  return useMutation(
    ({ id, name }: { id: number; name: string }) =>
      api.updateReport(id, { name }),
    {
      onSuccess: updateReport,
      onError: (e) => log('useUpdateReportName error: %O', e),
    },
  );
};

/**
 * Смотреть статус отчёта и обновлять раз в секунду (пока обработка не завершится)
 * @param report - отчёт
 */
export const useReportStatusRefresh = (report: TReport) => {
  const api = useApi();
  const updateReport = useUpdateReportInCache();

  const refresh = useCallback(async () => {
    try {
      const data = await api.getReport(report.id);
      updateReport(data);
    } catch (e) {
      log(e);
    }
  }, [api, report.id, updateReport]);

  useEffect(() => {
    if (report.status === 'Processed' || report.status === 'Error') {
      return;
    }
    const interval = setInterval(refresh, 1000);
    return () => clearInterval(interval);
  }, [refresh, report.status]);
};
