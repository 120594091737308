import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import { useQuery } from '@tanstack/react-query';
import { groupByPredicate } from '@payler/utils';
import dayjs from 'dayjs';
import { PaginationProps } from '@payler/ui-components';
import { TCreateReportPayloadV2 } from '@payler/api/merchant-cabinet';
import { useCallback } from 'react';
import createLogger from 'debug';
import { usePaymentsFiltersFromUrl } from './url';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { useDropReportsCache } from '../reports/cache';
import { DEFAULT_PAGER_PROPS } from '../../const';
import { useUIDateFormat } from '../use-ui-dateFormat';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../../context/ApiContextProvider';

const log = createLogger('hooks:payments:queries');

const PAYMENTS_STALE_TIME = 60 * 1000;
const PAYMENT_DATA_STALE_TIME = 600 * 1000;
const PAYMENTS_TEMPLATES_STALE_TIME = 600 * 1000;

export const usePaymentsQuery = () => {
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  const contractId = useURLContractId();
  const pagerData = usePagerPropsFromUrl();
  const paymentFilters = usePaymentsFiltersFromUrl();
  const dateFormat = useUIDateFormat();
  if (!contractId) throw new Error('no contractId');
  return useQuery(
    ['payments', culture, contractId, pagerData, paymentFilters],
    async () => {
      const data = await api.getPayments(
        contractId,
        {
          ...paymentFilters,
          ...pagerData,
        },
        dateFormat,
      );

      const grouped = groupByPredicate(data.items, (v) => {
        const dateString = v['firstOperationDate'] as string;
        return dayjs(dateString).format('YYYY-MM-DD');
      });
      return { ...data, grouped };
    },
    {
      staleTime: PAYMENTS_STALE_TIME,
    },
  );
};

export const usePaymentsQueryPagerProps = () => {
  const payments = usePaymentsQuery();
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (payments.status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { page, pageSize, totalItems } = payments.data.paginationMetadata;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: page,
    totalRecords: totalItems,
  } as PaginationProps;
};

export const usePaymentDataQuery = (id: number, suspense?: boolean) => {
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  return useQuery(['payments', culture, id], async () => api.getPayment(id), {
    staleTime: PAYMENT_DATA_STALE_TIME,
    suspense,
  });
};

export const usePaymentsTemplatesQuery = () => {
  const api = useApi();
  const contractId = useURLContractId();
  if (!contractId) throw new Error('no contractId');
  return useQuery(
    ['payments', 'templates', contractId],
    async () => {
      const res = await api.getPaymentsTemplates(contractId);
      return res.filter((x) => x.isAntifraudDeclined !== true);
    },
    {
      staleTime: PAYMENTS_TEMPLATES_STALE_TIME,
      suspense: true,
    },
  );
};

export const useSavePaymentsFiltersToReport = () => {
  const dropReportsCache = useDropReportsCache();
  const filters = usePaymentsFiltersFromUrl();
  const api = useApi();
  const contractId = useURLContractId();
  const dateFormat = useUIDateFormat();
  return useCallback(
    async (name: string) => {
      try {
        if (!contractId) return;
        const data: TCreateReportPayloadV2 = {
          name,
          contractIds: [contractId],
          paymentIndicators: filters.PaymentIndicators ?? [],
          paymentMethods: filters.PaymentMethods ?? [],
          currencyCodes: filters.CurrencyCodes ?? [],
          transactionStatuses: filters.TransactionStatuses ?? [],
          startDate: filters.StartDate,
          endDate: filters.EndDate,
          isAntifraudDeclined: filters.IsAntifraudDeclined,
        };

        await api.createReportV2(data, dateFormat);
        dropReportsCache();
      } catch (e) {
        log('useSavePaymentsFiltersToReport error: %O', e);
      }
    },
    [contractId, api, dropReportsCache, filters, dateFormat],
  );
};
