import { Button, VStack } from '@chakra-ui/react';
import {
  createDateFieldResolver,
  DatesFields,
  FloatingInputField,
  MultiDropdownField,
  SegmentedPicker,
  TRenderValueFunc,
} from '@payler/ui-components';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import {
  TCreateReportPayloadV2,
  TCreateReportPayloadV3,
  TReportFilterV3,
  TTransactionStatus,
} from '@payler/api/merchant-cabinet';
import dayjs from 'dayjs';
import createLogger from 'debug';
import { useCurrencyOptions } from '../../hooks/use-currency-options';
import { useTransactionsStatusOptions } from '../../hooks/use-transactions-status-options';
import { usePaymentIndicatorsOptions } from '../../hooks/use-payment-indicators-options';
import { intersection, isEqual } from 'lodash';
import { useGetContractsCurrencies } from '../../hooks/use-get-contracts-currencies';
import {
  APM_PAYMENTS_INDICATOR,
  APM_PAYMENTS_NEW_REPORT_STATUS_OPTIONS,
  NEW_REPORT_CREDIT_STATUSES,
  NEW_REPORT_DEBIT_STATUSES,
} from '../../const';
import { usePaymentMethodsOptions } from '../../hooks/use-payment-methods-options';
import {
  useCreateReportForRuSegmentMutation,
  useCreateReportForGlobalSegmentMutation,
} from '../../hooks/reports/misc';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useUIDateFormat } from '../../hooks/use-ui-dateFormat';
import {
  useMerchantContractsIds,
  useMerchantContractsOptions,
  useOnlyGlobalSegmentFlag,
} from '../../hooks/contracts';
import { useCheckMerchantCabinetFeatureFlag } from '../../config/ConfigProvider';
import { useApmPaymentsMethods } from '../../hooks/apm-payments/payment-methods';
import { useApmPaymentMethodsOptions } from '../../hooks/use-apm-payment-methods-options';

const log = createLogger('NewReportForm');

type Props = {
  onSubmit?: VoidFunction;
};

type FormDataGlobalSegment = Omit<
  TCreateReportPayloadV3 & TReportFilterV3,
  'filter'
>;

type FormDataRuSegment = Omit<TCreateReportPayloadV2, 'transactionStatuses'> & {
  transactionStatusesDebit: TTransactionStatus[];
  transactionStatusesCredit: TTransactionStatus[];
};

type FormData = FormDataRuSegment | FormDataGlobalSegment;

const REPORT_PAYMENT_TYPES_OPTIONS = APM_PAYMENTS_INDICATOR.map((o) => ({
  label: o,
  value: o.toLowerCase(),
}));

const APM_PAYMENT_STATUS_OPTIONS = APM_PAYMENTS_NEW_REPORT_STATUS_OPTIONS.map(
  (o) => ({
    label: o,
    value: o,
  }),
);

const useDefaultsForRuSegment = () => {
  const contractIds = useMerchantContractsIds();
  const dateFormat = useUIDateFormat();
  return useMemo(
    () => ({
      contractIds,
      name: '',
      startDate: dayjs().add(-1, 'month').format(dateFormat),
      endDate: dayjs().format(dateFormat),
      transactionStatusesDebit: NEW_REPORT_DEBIT_STATUSES,
      transactionStatusesCredit: NEW_REPORT_CREDIT_STATUSES,
      paymentMethods: [],
      paymentIndicators: [],
      currencyCodes: [],
    }),
    [contractIds, dateFormat],
  );
};

const useDefaultsForGlobalSegment = () => {
  const contractIds = useMerchantContractsIds();
  const dateFormat = useUIDateFormat();
  return useMemo<FormDataGlobalSegment>(
    () => ({
      contractIds,
      name: '',
      startDate: dayjs().add(-1, 'month').format(dateFormat),
      endDate: dayjs().format(dateFormat),
      paymentMethods: [],
      currencyCodes: [],
      paymentTypes: [],
      sessionStatuses: [],
    }),
    [contractIds, dateFormat],
  );
};

const useCreateResolver = () => {
  const { t } = useTranslation();
  const dateFormat = useUIDateFormat();
  return useCallback(() => {
    return yupResolver(
      yup.object({
        startDate: createDateFieldResolver(t, {
          toFieldName: 'endDate',
          maxDate: dayjs(),
          dateFormat,
        }),
        endDate: createDateFieldResolver(t, {
          fromFieldName: 'startDate',
          maxDate: dayjs(),
          dateFormat,
        }),
      }),
    );
  }, [dateFormat, t]);
};

export const NewReportForm = ({ onSubmit }: Props) => {
  return (
    <Suspense fallback={null}>
      <NewReportFormComp onSubmit={onSubmit} />
    </Suspense>
  );
};

const NewReportFormComp = ({ onSubmit: outerOnSubmit }: Props) => {
  const isEnabled = useCheckMerchantCabinetFeatureFlag(
    'reports.ru_global_splitting',
  );
  const isGlobal = useOnlyGlobalSegmentFlag();

  if (!isEnabled) {
    return <RuSegmentForm onSubmit={outerOnSubmit} />;
  }

  return isGlobal ? (
    <GlobalSegmentForm onSubmit={outerOnSubmit} />
  ) : (
    <RuSegmentForm onSubmit={outerOnSubmit} />
  );
};

const RuSegmentForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const createReportForRuSegmentMutation =
    useCreateReportForRuSegmentMutation();
  const dateFormat = useUIDateFormat();
  const [type, setType] = useState(0);
  const defaultValues = useDefaultsForRuSegment();
  const createResolver = useCreateResolver();
  const methods = useForm<FormDataRuSegment>({
    defaultValues,
    resolver: createResolver(),
  });
  const handleSubmit = methods.handleSubmit(async (data: FormDataRuSegment) => {
    const isDebit = type === 0;
    const { transactionStatusesCredit, transactionStatusesDebit, ...rest } =
      data;
    const payload: TCreateReportPayloadV2 = {
      ...rest,
      transactionStatuses: isDebit
        ? transactionStatusesDebit
        : transactionStatusesCredit,
    };
    log(createReportForRuSegmentMutation, payload);
    await createReportForRuSegmentMutation.mutateAsync(payload);
    onSubmit?.();
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <VStack alignItems="stretch" spacing={3}>
          <WatchContracts />
          <SegmentedPicker
            selection={type}
            options={[t('debit'), t('credit')]}
            onSelectionChange={setType}
            isFullWidth
          />
          <FloatingInputField
            name="name"
            label={t('fields:reportName')}
            isRequired
            autoFocus
            autoComplete="off"
          />
          <Contracts />
          <DatesFields
            labelsKeys={['fields:dateFrom', 'fields:dateTo']}
            fieldNames={['startDate', 'endDate']}
            floating
            dateFormat={dateFormat}
            maxDate={dayjs()}
          />
          {type === 0 && <StatusDebit />}
          {type === 1 && <StatusCredit />}
          {type === 0 && <PaymentMethods />}
          {type === 0 && <PaymentIndicators />}
          <Currencies />
          <Button
            type="submit"
            isLoading={createReportForRuSegmentMutation.isLoading}
          >
            {t('reports:create')}
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

const GlobalSegmentForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const createReportForGlobalSegmentMutation =
    useCreateReportForGlobalSegmentMutation();
  const dateFormat = useUIDateFormat();
  const defaultValues = useDefaultsForGlobalSegment();
  const createResolver = useCreateResolver();
  const methods = useForm<FormDataGlobalSegment>({
    defaultValues,
    resolver: createResolver(),
  });

  const handleSubmit = methods.handleSubmit(
    async (data: FormDataGlobalSegment) => {
      const { name, ...rest } = data;
      log(createReportForGlobalSegmentMutation, data);
      await createReportForGlobalSegmentMutation.mutateAsync({
        name,
        filter: rest,
      });
      onSubmit?.();
    },
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <VStack alignItems="stretch" spacing={3}>
          <WatchContracts isApm />
          <FloatingInputField
            name="name"
            label={t('fields:reportName')}
            isRequired
            autoFocus
            autoComplete="off"
          />
          <Contracts />
          <DatesFields
            labelsKeys={['fields:dateFrom', 'fields:dateTo']}
            fieldNames={['startDate', 'endDate']}
            floating
            dateFormat={dateFormat}
            maxDate={dayjs()}
          />
          <ApmPaymentStatuses />
          <ApmPaymentMethods />
          <PaymentTypes />
          <Currencies isApm />
          <Button
            type="submit"
            isLoading={createReportForGlobalSegmentMutation.isLoading}
          >
            {t('reports:create')}
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

const PaymentTypes = () => {
  const { t } = useTranslation('fields');
  return (
    <MultiDropdownField
      options={REPORT_PAYMENT_TYPES_OPTIONS}
      fieldName="paymentTypes"
      floating
      label={t('paymentIndicator')}
    />
  );
};

const ApmPaymentStatuses = () => {
  const { t } = useTranslation('fields');
  return (
    <MultiDropdownField
      options={APM_PAYMENT_STATUS_OPTIONS}
      fieldName="sessionStatuses"
      floating
      label={t('transactionStatus')}
    />
  );
};

const PaymentMethods = () => {
  const { options, fieldName } = usePaymentMethodsOptions();
  return (
    <MultiDropdownField
      options={options}
      fieldName="paymentMethods"
      floating
      label={fieldName}
    />
  );
};

const ApmPaymentMethods = () => {
  const { watch } = useFormContext();
  const contractIds = watch('contractIds');
  const { options, fieldName } = useApmPaymentMethodsOptions(contractIds);

  return (
    <MultiDropdownField
      options={options}
      fieldName="paymentMethods"
      floating
      label={fieldName}
    />
  );
};

const PaymentIndicators = () => {
  const { options, fieldName } = usePaymentIndicatorsOptions();
  return (
    <MultiDropdownField
      options={options}
      fieldName="paymentIndicators"
      floating
      label={fieldName}
      noEllipsis
    />
  );
};

const StatusDebit = () => {
  const { fieldName, options, selectAllText } = useTransactionsStatusOptions(
    NEW_REPORT_DEBIT_STATUSES,
  );
  return (
    <MultiDropdownField
      fieldName="transactionStatusesDebit"
      options={options}
      preset="mc2"
      label={fieldName}
      selectAllLabel={selectAllText}
    />
  );
};

const StatusCredit = () => {
  const { options, fieldName, selectAllText } = useTransactionsStatusOptions(
    NEW_REPORT_CREDIT_STATUSES,
  );
  return (
    <MultiDropdownField
      fieldName="transactionStatusesCredit"
      options={options}
      preset="mc2"
      label={fieldName}
      selectAllLabel={selectAllText}
    />
  );
};

const Contracts = () => {
  const { t } = useTranslation();
  const options = useMerchantContractsOptions();
  const isSingleContract = options.length === 1;

  const renderSingleValueCallback: TRenderValueFunc = useCallback(
    ({ props }) => props.options[0]?.label || '',
    [],
  );

  return (
    <MultiDropdownField
      fieldName="contractIds"
      options={options}
      renderValue={
        // INFO: Нельзя позволять очистить единственное значение
        isSingleContract ? renderSingleValueCallback : undefined
      }
      label={t('fields:contract')}
      selectAllLabel={!isSingleContract ? t('allContracts') : undefined}
      preset={!isSingleContract ? 'mc3' : undefined}
      hideClearButton={isSingleContract}
    />
  );
};

const Currencies: FC<{ isApm?: boolean }> = ({ isApm }) => {
  const { watch } = useFormContext<FormData>();
  const contractIds = watch('contractIds');
  const { options, fieldName } = useCurrencyOptions({
    contractIds,
    isApm,
  });

  return (
    <MultiDropdownField
      options={options}
      fieldName="currencyCodes"
      floating
      label={fieldName}
      noEllipsis
    />
  );
};

const WatchContracts: FC<{ isApm?: boolean }> = ({ isApm }) => {
  const { watch, setValue } = useFormContext<FormData>();
  const [contractIds, currencyCodes, paymentMethods] = watch([
    'contractIds',
    'currencyCodes',
    'paymentMethods',
  ]);
  const getCurrencies = useGetContractsCurrencies(isApm);
  const availablePaymentsMethods = useApmPaymentsMethods(contractIds);

  useEffect(() => {
    const currencies = getCurrencies(contractIds);
    const resCurrencies = intersection(currencyCodes, currencies);

    if (!isEqual(currencyCodes?.sort(), resCurrencies?.sort())) {
      setValue('currencyCodes', resCurrencies);
    }
  }, [contractIds, currencyCodes, getCurrencies, setValue]);

  useEffect(() => {
    if (!isApm) {
      return;
    }
    const resPaymentsMethods = intersection(
      availablePaymentsMethods,
      paymentMethods,
    );
    if (!isEqual(paymentMethods?.sort(), resPaymentsMethods?.sort())) {
      setValue('paymentMethods', resPaymentsMethods);
    }
  }, [availablePaymentsMethods, isApm, paymentMethods, setValue]);

  return null;
};
