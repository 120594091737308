import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { TReport, TReportsResponse } from '@payler/api/merchant-cabinet';
import createLogger from 'debug';

const log = createLogger('query:reports:cache');

/**
 * Сбросить кэш отчётов
 */
export const useDropReportsCache = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient
      .invalidateQueries(['reports'])
      .then(() => log('reports cache dropped'));
  }, [queryClient]);
};

/**
 * Обновить данные отчёта в кеше
 */
export const useUpdateReportInCache = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (data: Partial<TReport> & { id: number }) => {
      queryClient.setQueriesData<TReportsResponse>(['reports'], (old) => {
        if (!old) return old;
        const hasItem = old.items.find((x) => x.id === data.id);
        if (!hasItem) return old;
        return {
          ...old,
          items: old.items.map((x) =>
            x.id === data.id ? { ...x, ...data } : x,
          ),
        };
      });
    },
    [queryClient],
  );
};
