import { useCallback } from 'react';
import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import { useQueryClient } from '@tanstack/react-query';
import createLogger from 'debug';
import { useApi } from '../../context/ApiContextProvider';

const log = createLogger('hooks:payments');

/**
 * Обновить данные платежа
 */
export const useRefreshPaymentData = () => {
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  const client = useQueryClient();
  return useCallback(
    async (id: number) => {
      try {
        const data = await api.getPayment(id);
        client.setQueryData(['payments', culture, id], data);
      } catch (e) {
        log('useRefreshPaymentData error: %O', e);
      }
    },
    [api, client, culture],
  );
};

/**
 * Списание суммы через ядро
 */
export const useChargePayment = () => {
  const client = useQueryClient();
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  return useCallback(
    async (id: number, amount: number, divider: number) => {
      const response = await api.chargePayment(id, amount, divider);
      log('useChargePayment response: %O', response);
      await client.refetchQueries(['payments', culture, id]);
    },
    [api, client, culture],
  );
};

/**
 * Возврат суммы через ядро
 */
export const useRefundPayment = () => {
  const client = useQueryClient();
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  return useCallback(
    async (id: number, amount: number, password: string, divider: number) => {
      const response = await api.refundPayment(id, amount, password, divider);
      log('useRefundPayment response: %O', response);
      await client.refetchQueries(['payments', culture, id]);
    },
    [api, client, culture],
  );
};

/**
 * Разблокировка суммы через ядро
 */
export const useRetrievePayment = () => {
  const client = useQueryClient();
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  return useCallback(
    async (id: number, amount: number, divider: number) => {
      const response = await api.retrievePayment(id, amount, divider);
      log('useRetrievePayment response: %O', response);
      await client.refetchQueries(['payments', culture, id]);
    },
    [api, client, culture],
  );
};
