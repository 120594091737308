import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { groupByPredicate } from '@payler/utils';
import dayjs from 'dayjs';
import { PaginationProps } from '@payler/ui-components';
import {
  TCreatePayoutRequest,
  TCreateReportPayloadV2,
} from '@payler/api/merchant-cabinet';
import { useCallback } from 'react';
import createLogger from 'debug';
import { usePayoutsFiltersFromUrl } from './url';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { useDropReportsCache } from '../reports/cache';
import { DEFAULT_PAGER_PROPS } from '../../const';
import { useCurrencyCallbacks } from '../currencies-info';
import { useUIDateFormat } from '../use-ui-dateFormat';
import { usePreviousDate } from '../use-previous-date';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../../context/ApiContextProvider';

const log = createLogger('hooks:payments:queries');

const PAYOUTS_STALE_TIME = 60 * 1000;
const PAYOUTS_TEMPLATES_STALE_TIME = 600 * 1000;

export const usePayoutsQuery = (context: 'payouts' | 'withdrawals') => {
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  const contractId = useURLContractId();
  const pagerData = usePagerPropsFromUrl();
  const urlFilters = usePayoutsFiltersFromUrl();
  const previousDate = usePreviousDate();
  const filters =
    context === 'payouts' ? urlFilters : { StartDate: previousDate };
  if (!contractId) throw new Error('no contractId');
  return useQuery(
    ['payouts', culture, contractId, pagerData, filters],
    async () => {
      const data = await api.getPayouts({
        ContractId: contractId,
        ...filters,
        ...pagerData,
      });

      const grouped = groupByPredicate(data.items, (v) => {
        const dateString = v['firstOperationDate'] as string;
        return dayjs(dateString).format('YYYY-MM-DD');
      });
      return { ...data, grouped };
    },
    {
      staleTime: PAYOUTS_STALE_TIME,
    },
  );
};

export const usePayoutsQueryPagerProps = (
  context: 'payouts' | 'withdrawals',
) => {
  const payments = usePayoutsQuery(context);
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (payments.status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { page, pageSize, totalItems } = payments.data.paginationMetadata;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: page,
    totalRecords: totalItems,
  } as PaginationProps;
};

export const usePayoutsTemplatesQuery = () => {
  const api = useApi();
  const contractId = useURLContractId();
  if (!contractId) throw new Error('no contractId');
  return useQuery(
    ['payouts', 'templates', contractId],
    async () => api.getPayoutsTemplates(contractId),
    {
      staleTime: PAYOUTS_TEMPLATES_STALE_TIME,
      suspense: true,
    },
  );
};

export const useSavePayoutsFiltersToReport = () => {
  const dropReportsCache = useDropReportsCache();
  const filters = usePayoutsFiltersFromUrl();
  const api = useApi();
  const contractId = useURLContractId();
  const dateFormat = useUIDateFormat();
  return useCallback(
    async (name: string) => {
      try {
        if (!contractId) return;
        const data: TCreateReportPayloadV2 = {
          name,
          contractIds: [contractId],
          currencyCodes: filters.CurrencyCodes ?? [],
          transactionStatuses: filters.TransactionStatuses ?? [],
          startDate: filters.StartDate,
          endDate: filters.EndDate,
        };

        await api.createReportV2(data, dateFormat);
        dropReportsCache();
      } catch (e) {
        log('useSavePaymentsFiltersToReport error: %O', e);
      }
    },
    [contractId, api, dropReportsCache, filters, dateFormat],
  );
};

/**
 * рефрешим страницы сделать выплату и выплаты/список и переходим на первую страницу
 */
export const useDropPayoutsQueryCache = () => {
  const client = useQueryClient();
  const { goToPage } = usePayoutsQueryPagerProps('payouts');
  return useCallback(() => {
    client.invalidateQueries(['payouts']).then(() => goToPage(0));
  }, [client, goToPage]);
};

export const useMakeCreditMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { getCurrencyUnitRate } = useCurrencyCallbacks();
  return useMutation(
    (data: TCreatePayoutRequest) =>
      api.createPayout(data, getCurrencyUnitRate(data.currency)),
    {
      onSuccess: (_, { contractId }) => {
        queryClient
          .refetchQueries(['balances', contractId])
          .catch(() => log('failed to refetch balance'));
      },
    },
  );
};
