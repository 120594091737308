import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { PaginationProps } from '@payler/ui-components';
import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { useQuery } from '@tanstack/react-query';
import { DEFAULT_PAGER_PROPS } from '../../const';
import { useApi } from '../../context/ApiContextProvider';

const REPORTS_STALE_TIME = 60 * 1000;

/**
 * Получить список отчётов для текущей страницы
 */
export const useReportsQuery = () => {
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  const { Page, PageSize } = usePagerPropsFromUrl();
  return useQuery(
    ['reports', culture, { Page, PageSize }],
    () => api.getReports(Page, PageSize),
    {
      staleTime: REPORTS_STALE_TIME,
    },
  );
};

/**
 * Пропсы пэйджера для текущей страницы отчётов
 */
export const useReportsQueryPagerProps = () => {
  const reports = useReportsQuery();
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (reports.status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { page, pageSize, totalItems } = reports.data.paginationMetadata;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: page,
    totalRecords: totalItems,
  } as PaginationProps;
};
