export const menuEn = {
  alerts: 'Alerts',
  analytics: 'Analytics',
  antifraud: 'Antifraud',
  invoice: 'Invoice',
  list: 'List',
  logout: 'Log out',
  makecredit: 'Withdraw',
  payments: 'Payments',
  reports: 'Reports',
  sbpLink: 'Payouts',
  sbpTitle: 'FPS',
  sbpPaymentsLink: 'Payments',
  apmTitle: 'APM',
  apmLink: 'Payouts',
  apmPaymentsLink: 'Payments',
  settings: 'Settings',
  withdrawals: 'Withdrawals',
  account: 'Account',
  payment: 'Payment',
  notifications: 'Notifications',
  users: 'Users',
  cryptoPaymentsTitle: 'Crypto payments',
  cryptoPaymentsLink: 'Crypto payments',
};

type TMenuTranslation = typeof menuEn;

export const menuEs: TMenuTranslation = {
  alerts: 'Alertas',
  analytics: 'Analítica',
  antifraud: 'Antifraude',
  invoice: 'Facturación',
  list: 'Lista',
  logout: 'Salir',
  makecredit: 'Hacer un pago',
  payments: 'Pagos',
  reports: 'Informes',
  sbpLink: 'Transferencias',
  sbpPaymentsLink: 'Pagos',
  sbpTitle: 'FPS',
  apmTitle: 'APM',
  apmLink: 'Transferencias',
  apmPaymentsLink: 'Pagos',
  settings: 'Configuraciones',
  withdrawals: 'Retirada',
  account: 'Cuenta',
  payment: 'Pago',
  notifications: 'Notificaciones',
  users: 'Usuarios',
  cryptoPaymentsTitle: 'Crypto payments',
  cryptoPaymentsLink: 'Crypto payments',
};

export const menuRu: TMenuTranslation = {
  alerts: 'Оповещения',
  analytics: 'Аналитика',
  antifraud: 'Антифрод',
  invoice: 'Выставить счет',
  list: 'Список',
  logout: 'Выйти',
  makecredit: 'Сделать выплату',
  payments: 'Платежи',
  reports: 'Отчеты',
  sbpLink: 'Выплаты',
  sbpPaymentsLink: 'Платежи',
  sbpTitle: 'СБП',
  apmTitle: 'APM',
  apmLink: 'Выплаты',
  apmPaymentsLink: 'Платежи',
  settings: 'Настройки',
  withdrawals: 'Выплаты',
  account: 'Аккаунт',
  payment: 'Оплата',
  notifications: 'Уведомления',
  users: 'Пользователи',
  cryptoPaymentsTitle: 'Crypto платежи',
  cryptoPaymentsLink: 'Crypto платежи',
};
