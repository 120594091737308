import {
  Badge,
  chakra,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import {
  DynamicShortText,
  ErrorBoundary,
  NoData,
  ScrollBarWithFade,
} from '@payler/ui-components';
import { TableSkeleton } from '../../components/TableSkeleton/TableSkeleton';
import { useTimeAgo } from '../../hooks/use-time-ago';
import { useDayjsFormat } from '@payler/utils';
import { TableFooter } from '../components/TableFooter';
import { tableStyleConfig } from '../tableStyleConfig';
import { GroupRow } from '../PaymentsTable/GroupRow';
import { AmountWithCurrencyCell } from '../PaymentsTable/AmountWithCurrencyCell';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useCryptoPaymentsQuery,
  useCryptoPaymentsQueryPagerProps,
} from '../../hooks/crypto-payments/queries';
import { TCryptoPayment } from '@payler/api/merchant-cabinet';
import { TextStyles } from '@payler/ui-theme';
import { getSessionStatusBadgeVariant } from './getSessionStatusBadgeVariant';

export const CryptoPaymentsTable = () => {
  const props = useCryptoPaymentsQueryPagerProps();
  return (
    <>
      <React.Suspense fallback={null}>
        <TableWithData />
      </React.Suspense>
      <ErrorBoundary>
        <React.Suspense fallback={null}>
          <TableFooter paginationProps={props} />
        </React.Suspense>
      </ErrorBoundary>
    </>
  );
};

const TwoLevelCell: FC<{ top: string | number; bottom: string | number }> = ({
  top,
  bottom,
}) => {
  return (
    <VStack alignItems="flex-start" spacing={0}>
      <chakra.span textStyle={TextStyles.tables}>{top}</chakra.span>
      <chakra.span textStyle={TextStyles.Caption12Regular} color="primary.350">
        {bottom}
      </chakra.span>
    </VStack>
  );
};

const DateCell: FC<{ date: string }> = ({ date }) => {
  const parsedDate = dayjs(date).format('DD.MM.YYYY');
  const parsedTime = dayjs(date).format('HH:mm:ss');

  return <TwoLevelCell top={parsedDate} bottom={parsedTime} />;
};

const CurrencyCell: FC<{ currency: string; network: string }> = ({
  currency,
  network,
}) => {
  return <TwoLevelCell top={currency} bottom={network} />;
};

const TableWithData = () => {
  const { status, data } = useCryptoPaymentsQuery();
  switch (status) {
    case 'loading':
      return <TableSkeleton />;
    case 'error':
      return <NoData />;
    case 'success':
    default: {
      const empty = data.items.length === 0;
      if (empty) {
        return <NoData />;
      }
      return (
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Header />
              <Tbody>
                {Object.entries(data.grouped)
                  .sort(([a], [b]) => -a.localeCompare(b))
                  .map(([group, rows]) => (
                    <Rows key={group} group={group} payments={rows} />
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      );
    }
  }
};

const Rows = ({
  group,
  payments,
}: {
  group: string;
  payments: TCryptoPayment[];
}) => {
  const format = useDayjsFormat();
  const timeAgo = useTimeAgo();
  return (
    <>
      <tr>
        <GroupRow colSpan={10}>
          {format(group, 'dddd DD MMMM')}
          <Text as="span" color="primary.300">
            &nbsp;&nbsp;&mdash;&nbsp;&nbsp;{timeAgo.format(new Date(group))}
          </Text>
        </GroupRow>
      </tr>
      {payments.map((row) => (
        <Row data={row} key={row.sessionId} />
      ))}
    </>
  );
};

const Row = ({ data }: { data: TCryptoPayment }) => {
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(data.sessionId);
  };

  return (
    <Tr onClick={handleRowClick}>
      <Td>
        <CurrencyCell
          currency={data.currency ?? '-'}
          network={data.network ?? '-'}
        />
      </Td>
      <Td>
        <DateCell date={data.createdAt ?? '-'} />
      </Td>
      <Td>
        <AmountWithCurrencyCell
          amount={data.amount ?? 0}
          currencyCode={data.currency}
          amountOfRefunds={0}
        />
      </Td>
      <Td>{data.clientId ?? '-'}</Td>
      <Td>
        {data.paymentType ? (
          <Badge colorScheme="blue">{data.paymentType}</Badge>
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {data.sessionStatus ? (
          <Badge variant={getSessionStatusBadgeVariant(data.sessionStatus)}>
            {data.sessionStatus}
          </Badge>
        ) : (
          '-'
        )}
      </Td>

      <Td>
        <DynamicShortText
          maxTextWidth={16}
          textStyle={TextStyles.BodyUI16Regular}
          cutDirection="both"
          cutLimit={4}
          protectedSymbolsCount={4}
          divider="..."
        >
          {data.txId ?? '-'}
        </DynamicShortText>
      </Td>
    </Tr>
  );
};

const Header = () => {
  const { t } = useTranslation('tableHeader');
  return (
    <Thead>
      <Tr>
        <Th>{t('currency')}</Th>
        <Th>{t('date')}</Th>
        <Th>{t('amount')}</Th>
        <Th>{t('clientId')}</Th>
        <Th>{t('paymentType')}</Th>
        <Th>{t('status')}</Th>
        <Th>{t('txHash')}</Th>
      </Tr>
    </Thead>
  );
};

export default CryptoPaymentsTable;
