import { useApi } from '../../context/ApiContextProvider';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { useQuery } from '@tanstack/react-query';
import { groupByPredicate } from '@payler/utils';
import dayjs from 'dayjs';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { DEFAULT_PAGER_PROPS } from '../../const';
import { PaginationProps } from '@payler/ui-components';
import {
  useApmPaymentSessionIdFromUrl,
  useApmPaymentsFiltersFromUrl,
} from './url';

const APM_PAYMENTS_STALE_TIME = 60 * 1000;

export const useApmPaymentsQuery = () => {
  const api = useApi();
  const contractId = useURLContractId();
  const pagerData = usePagerPropsFromUrl();
  const filters = useApmPaymentsFiltersFromUrl();

  if (!contractId) throw new Error('no contractId');
  return useQuery(
    ['apm-payments', contractId, pagerData, filters],
    async () => {
      const data = await api.getApmPayments(contractId, {
        ...filters,
        ...pagerData,
      });

      const grouped = groupByPredicate(data.items, (v) => {
        const dateString = v['createdAt'] as string;
        return dayjs(dateString).format('YYYY-MM-DD');
      });
      return { ...data, grouped };
    },
    {
      staleTime: APM_PAYMENTS_STALE_TIME,
    },
  );
};

export const useApmPaymentsQueryPagerProps = () => {
  const payments = useApmPaymentsQuery();
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (payments.status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { page, pageSize, totalItems } = payments.data.paginationMetadata;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: page,
    totalRecords: totalItems,
  } as PaginationProps;
};
export const useApmPaymentsTemplatesQuery = () => {
  const api = useApi();
  const contractId = useURLContractId();
  if (!contractId) throw new Error('no contract Id');
  return useQuery(
    ['apm-payments', 'templates', contractId],
    async () => api.getApmPaymentsTemplates(contractId),
    {
      staleTime: APM_PAYMENTS_STALE_TIME,
      suspense: true,
    },
  );
};

export const useApmPaymentSessionQuery = () => {
  const api = useApi();
  const sessionId = useApmPaymentSessionIdFromUrl();
  return useQuery(
    ['session', sessionId],
    async () => {
      const data = await api.getApmPaymentSession(sessionId);

      return data.session;
    },
    {
      staleTime: APM_PAYMENTS_STALE_TIME,
      suspense: true,
    },
  );
};
