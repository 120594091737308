import { TCreateUserForm } from './types';
import { FormProvider, useForm } from 'react-hook-form';
import {
  DropdownField,
  FloatingInputField,
  LoadingBox,
  MultiDropdownField,
} from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import {
  useContracts,
  useMerchantContractsOptions,
} from '../../hooks/contracts';
import { FC, memo, Suspense, useEffect, useMemo, useState } from 'react';
import { TextStyles } from '@payler/ui-theme';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddChildUserMutation } from '../../hooks/user/users';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { TCreateUserRequest } from '@payler/api/merchant-cabinet';
import { useToasts } from '../../hooks/use-toasts';

type Props = {
  onCancel?: VoidFunction;
  onSubmit?: VoidFunction;
};

const useDefaultValues = () => {
  const contracts = useContracts();
  return useMemo<TCreateUserForm>(
    () => ({
      name: '',
      email: '',
      contractIds: contracts?.map((x) => x.id) ?? [],
      settingsNotifications: 'ALL',
      settingsPayment: 'ALL',
      payments: 'ALL',
      newCredit: 'ALL',
      newPayment: 'ALL',
      reports: 'ALL',
    }),
    [contracts],
  );
};

const useResolver = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return yupResolver(
      yup.object({
        name: yup
          .string()
          .required(t('users:invalidName'))
          .min(2, t('users:invalidName'))
          .max(120, t('users:invalidName')),
        email: yup
          .string()
          .email(t('users:invalidEmail'))
          .required(t('users:invalidEmail')),
      }),
    );
  }, [t]);
};

const Comp: FC<Props> = ({ onCancel, onSubmit }) => {
  const [error, setError] = useState('');
  const { mutateAsync, isLoading } = useAddChildUserMutation();
  const { t } = useTranslation('users');
  const toasts = useToasts();
  const resolver = useResolver();
  const defaultValues = useDefaultValues();
  const getError = useGetAxiosError();
  const methods = useForm<TCreateUserForm>({
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      onSubmit?.();
    }
  }, [methods.formState.isSubmitSuccessful, onSubmit]);

  const handleSubmit = methods.handleSubmit(async (values) => {
    await mutateAsync(prepareRequest(values), {
      onSuccess: () => {
        toasts.success(t('created'));
      },
      onError: (e) => {
        setError(getError(e));
        throw e;
      },
    });
  });

  const claimOptions = useMemo(() => {
    return [
      {
        label: t('roles:claimValues.ALL'),
        value: 'ALL',
      },
      {
        label: t('roles:claimValues.READ'),
        value: 'READ',
      },
    ];
  }, [t]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <VStack spacing={2} alignItems="stretch">
          <FloatingInputField
            name="name"
            label={t('name')}
            autoComplete="off"
            autoFocus
          />
          <FloatingInputField
            name="email"
            label={t('email')}
            autoComplete="off"
          />
          <Contracts />
          <Text textStyle={TextStyles.h4}>{t('options')}</Text>
          <DropdownField
            fieldName="newCredit"
            floating
            label={t('roles:claims.NEW_CREDIT')}
            options={claimOptions}
            hideClearButton
          />
          <DropdownField
            fieldName="newPayment"
            floating
            label={t('roles:claims.NEW_PAYMENT')}
            options={claimOptions}
            hideClearButton
          />
          <DropdownField
            fieldName="reports"
            floating
            label={t('roles:claims.REPORTS')}
            options={claimOptions}
            hideClearButton
          />
          <DropdownField
            fieldName="payments"
            floating
            label={t('roles:claims.PAYMENTS')}
            options={claimOptions}
            hideClearButton
          />
          <DropdownField
            fieldName="settingsNotifications"
            floating
            label={t('roles:claims.SETTING_NOTIFICATION')}
            options={claimOptions}
            hideClearButton
          />
          <DropdownField
            fieldName="settingsPayment"
            floating
            label={t('roles:claims.SETTING_PAYMENT')}
            options={claimOptions}
            hideClearButton
          />
          {error && <ApiErrorText>{error}</ApiErrorText>}
          <HStack pt={3} spacing={2}>
            <Button
              flex={1}
              variant="secondary"
              onClick={onCancel}
              isDisabled={isLoading}
            >
              {t('cancel')}
            </Button>
            <Button flex={1} onClick={handleSubmit} isLoading={isLoading}>
              {t('createUser')}
            </Button>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  );
};

export const CreateUserForm = memo((props: Props) => (
  <Suspense fallback={<LoadingBox h="700px" />}>
    <Comp {...props} />
  </Suspense>
));

export default CreateUserForm;

const Contracts = () => {
  const { t } = useTranslation();
  const options = useMerchantContractsOptions();
  return (
    <MultiDropdownField
      options={options}
      preset="mc3"
      fieldName="contractIds"
      selectAllLabel={t('allContracts')}
      label={t('users:contracts')}
    />
  );
};

const prepareRequest = (formData: TCreateUserForm): TCreateUserRequest => {
  return {
    name: formData.name,
    email: formData.email,
    options: {
      NEW_CREDIT: formData.newCredit,
      NEW_PAYMENT: formData.newPayment,
      PAYMENTS: formData.payments,
      REPORTS: formData.reports,
      SETTING_NOTIFICATION: formData.settingsNotifications,
      SETTING_PAYMENT: formData.settingsPayment,
    },
    contractIds: formData.contractIds,
  };
};
