import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, HStack, VStack } from '@chakra-ui/react';
import { DropdownField, FloatingInputField } from '@payler/ui-components';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { useTranslation } from 'react-i18next';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TExtendedContract } from '@payler/api/merchant-cabinet';
import { useMerchantPaymentTypeOptions } from '../../hooks/useMerchantPaymentTypeOptions';
import { merchantTerminalLanguageOptions } from '../../helpers/consts';
import { useUpdateContract } from '../../hooks/contracts';
import { useToasts } from '../../hooks/use-toasts';
import { HelpText } from '../../components/HelpText';
import { PASSWORD_REGEXP } from '../../const';

type FormData = {
  ips: string;
  lang: string;
  url: string;
  paymentType: number;
  currentPassword: string;
  newPassword?: string;
  confirmPassword?: string;
};
const URL_REGEXP = new RegExp(
  /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.{}]+$/,
);
const IPS_REGEXP = new RegExp(
  /^(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?),)*((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
);

function createResolver(t: TFunction) {
  return yupResolver(
    yup.object().shape({
      ips: yup.string().test((value, ctx) => {
        if (!value) return true;
        if (value && !IPS_REGEXP.test(value))
          return ctx.createError({
            message: t('validate.ips'),
          });
        return true;
      }),
      url: yup.string().matches(URL_REGEXP, t('validate.url')),
      currentPassword: yup.string(),
      newPassword: yup.string().test((value, ctx) => {
        if (!value) {
          return true;
        }
        if (value.length < 8) {
          return ctx.createError({
            message: t('validate.minPasswordLength'),
          });
        }
        if (value && !PASSWORD_REGEXP.test(value)) {
          return ctx.createError({
            message: t('validate.notMatchPasswordPattern'),
          });
        }
        return true;
      }),
      confirmPassword: yup.string().test((value, ctx) => {
        if (!ctx.parent.newPassword) {
          return true;
        }
        if (value !== ctx.parent.newPassword)
          return ctx.createError({
            message: t('validate.doNotMatchPassword'),
          });
        if (value && !PASSWORD_REGEXP.test(value)) {
          return ctx.createError({
            message: t('validate.notMatchPasswordPattern'),
          });
        }
        return true;
      }),
    }),
  );
}

export const PaymentSettingsForm: React.FC<{
  contract: TExtendedContract;
  onCancel: () => void;
}> = ({ contract, onCancel }) => {
  const { t } = useTranslation();
  const paymentTypeOptions = useMerchantPaymentTypeOptions();
  const [apiError, setApiError] = useState('');
  const getError = useGetAxiosError();
  const { mutateAsync, isLoading } = useUpdateContract();
  const toasts = useToasts();
  const methods = useForm<FormData>({
    resolver: createResolver(t),
    defaultValues: {
      ips: contract?.allowedIps?.join(',') ?? '',
      lang: contract?.lang ?? 'ru',
      url: contract?.extCustomerUrl ?? '',
      paymentType: contract?.paymentType ?? undefined,
    },
  });

  const onSubmit = methods.handleSubmit(
    async ({
      ips,
      lang,
      url,
      paymentType,
      currentPassword,
      newPassword,
      confirmPassword,
    }: FormData) => {
      setApiError('');
      await mutateAsync(
        {
          contractId: contract.id,
          payload: {
            lang,
            paymentType,
            extCustomerUrl: url,
            ipList: ips === '' ? null : ips,
            password: currentPassword,
            confirmNewPassword: confirmPassword,
            newPassword,
          },
        },
        {
          onSuccess: () => toasts.success(t('settings:payment.toast.success')),
          onError: (e) => setApiError(getError(e)),
        },
      );
    },
  );
  if (!contract) return null;
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <VStack alignItems="stretch" spacing={3}>
          <FloatingInputField
            name="ips"
            label={t('settings:payment.form.ips')}
            helpText={t('settings:payment.ipsHelp')}
          />
          <div>
            <DropdownField
              options={merchantTerminalLanguageOptions}
              fieldName="lang"
              floating
              label={t('settings:payment.form.lang')}
              hideClearButton
            />
            <HelpText>{t('settings:payment.langHelp')}</HelpText>
          </div>
          <FloatingInputField
            name="url"
            label={t('settings:payment.form.url')}
            helpText={t('settings:payment.urlHelp')}
          />
          <div>
            <DropdownField
              options={paymentTypeOptions}
              fieldName="paymentType"
              floating
              label={t('settings:payment.form.paymentType')}
              hideClearButton
            />
            <HelpText>{t('settings:payment.paymentTypeHelp')}</HelpText>
          </div>
          <FloatingInputField
            autoComplete="off"
            type="password"
            name="currentPassword"
            label={t('settings:payment.form.currentPassword')}
          />
          <FloatingInputField
            autoComplete="off"
            type="password"
            name="newPassword"
            label={t('settings:payment.form.newPassword')}
          />
          <FloatingInputField
            autoComplete="off"
            type="password"
            name="confirmPassword"
            label={t('settings:payment.form.confirmPassword')}
          />
          {apiError && <ApiErrorText>{apiError}</ApiErrorText>}
          <HStack spacing={2}>
            <Button
              variant="primary"
              type="submit"
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              {t('save')}
            </Button>
            <Button
              variant="secondary"
              onClick={onCancel}
              isDisabled={isLoading}
            >
              {t('cancel')}
            </Button>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  );
};
