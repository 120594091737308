export const fieldsEn = {
  amount: 'Amount',
  contract: 'Contract',
  currency: 'Currency',
  dateFrom: 'Period from',
  dateTo: 'Period to',
  from: 'From',
  paymentIndicator: 'Payment type',
  paymentMethod: 'Payment method',
  paymentStatus: 'Payment status',
  transactionStatus: 'Payment status',
  transactionNumber: 'Transaction number (order id)',
  period: 'Date range',
  reportName: 'Report name',
  role: 'Role',
  status: 'Status',
  to: 'To',
  notificationMethod: 'Notification method',
  orderId: 'Order ID',
  operationId: 'Operation ID',
  publicId: 'Public ID',
  sessionId: 'Session ID',
  orderIdApm: 'Order ID',
  clientIdCrypto: 'Client ID',
};
type TFieldsTranslation = typeof fieldsEn;

export const fieldsEs: TFieldsTranslation = {
  amount: 'Facturación',
  contract: 'Contrato',
  currency: 'Moneda',
  dateFrom: 'Período desde',
  dateTo: 'Período hasta',
  from: 'Desde',
  paymentIndicator: 'Tipo de pago',
  paymentMethod: 'Forma de pago',
  paymentStatus: 'Estado de pago',
  transactionStatus: 'Estado de pago',
  transactionNumber: 'Número de transacción (order id)',
  period: 'Rango de fechas',
  reportName: 'Nombre del informe',
  role: 'Role',
  status: 'Estado',
  to: 'Hasta',
  notificationMethod: 'Método de notificación',
  orderId: 'Solicitar ID',
  operationId: 'Identificación de operación',
  publicId: 'Identificación pública',
  sessionId: 'Identificación de sesión',
  orderIdApm: 'Solicitar ID',
  clientIdCrypto: 'Client ID',
};

export const fieldsRu: TFieldsTranslation = {
  amount: 'Сумма',
  contract: 'Контракт',
  currency: 'Валюта',
  dateFrom: 'Период от',
  dateTo: 'Период до',
  from: 'От',
  paymentIndicator: 'Признак платежа',
  paymentMethod: 'Способ оплаты',
  paymentStatus: 'Статус платежа',
  transactionStatus: 'Статус платежа',
  transactionNumber: 'Номер транзакции (order id)',
  period: 'Период',
  reportName: 'Название отчета',
  role: 'Роль',
  status: 'Статус',
  to: 'До',
  notificationMethod: 'Способ уведомления',
  orderId: 'ID заказа',
  operationId: 'ID операции',
  publicId: 'Публичный идентификатор',
  sessionId: 'Идентификатор сессии',
  orderIdApm: 'Номер транзакции (order id)',
  clientIdCrypto: 'Идентификатор клиента',
};
