import {
  TCreateUserRequest,
  TCurrentUserInfo,
  TInterfaceLanguage,
  TUpdateUserRequest,
  TUserClaimType,
  TUserClaimValue,
} from '@payler/api/merchant-cabinet';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { setTokenAndDispatchEvent } from '../../context/AuthContextProvider';
import { useApi } from '../../context/ApiContextProvider';

const CURRENT_USER_STALE_TIME = 60 * 6e5;
/**
 * Данные текущего пользователя
 */
export const useUserInfo = () => {
  const api = useApi();
  const { data } = useQuery(
    ['user'],
    () => {
      return api.getUserInfo();
    },
    {
      staleTime: CURRENT_USER_STALE_TIME,
    },
  );
  return data;
};

/**
 * Права текущего пользователя
 */
export const useUserPermissions = () => {
  const user = useUserInfo();
  const getter = (
    actionType: TUserClaimType,
    actionValue: TUserClaimValue,
  ): boolean => {
    return !!user?.user.claims.find(
      (x) => x.type === actionType && x.value === actionValue,
    );
  };
  return {
    canCreateUsers: !!user?.user.roles.includes('MC_ADMIN'),
    canCreateNotifications: getter('SETTING_NOTIFICATION', 'ALL'),
    invoices: {
      create: getter('NEW_PAYMENT', 'ALL'),
      resend: getter('NEW_PAYMENT', 'ALL'),
      notify: getter('NEW_PAYMENT', 'ALL'),
      copyLink: getter('NEW_PAYMENT', 'ALL'),
    },
    canCreateReports: getter('REPORTS', 'ALL'),
    canDeleteReports: getter('REPORTS', 'ALL'),
    canWithdraw: getter('NEW_CREDIT', 'ALL'),
    canChangePaymentSettings: getter('SETTING_PAYMENT', 'ALL'),
    canDoPayments: getter('PAYMENTS', 'ALL'),
  };
};

/**
 * Обновление языка
 */
export const useUpdateUserLangMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (lang: TInterfaceLanguage) => {
      return api.updateUserLanguage(lang);
    },
    onSuccess: (response: { interfaceLanguage: TInterfaceLanguage }) => {
      queryClient.setQueryData<TCurrentUserInfo>(['user'], (data) => {
        if (!data) return undefined;
        return {
          ...data,
          user: {
            ...data.user,
            interfaceLanguage: response.interfaceLanguage,
          },
        };
      });
    },
  });
};

/**
 * Обновление пароля
 */
export const useUpdateUserPasswordMutation = () => {
  const api = useApi();
  return useMutation({
    mutationFn: ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => {
      return api.updateUserPassword(currentPassword, newPassword);
    },
    onSuccess: (response) => {
      setTokenAndDispatchEvent(response.token);
    },
  });
};

/**
 * Добавить дочернего пользователя
 */
export const useAddChildUserMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: TCreateUserRequest) => {
      return api.createUser(data);
    },
    onSuccess: (newUser) => {
      queryClient.setQueryData<TCurrentUserInfo>(['user'], (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          childUsers: [newUser, ...prev.childUsers],
        };
      });
    },
  });
};

/**
 * Удалить дочернего пользователя
 */
export const useDeleteUserMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => {
      return api.deleteUser(id);
    },
    onSuccess: (_, id) => {
      queryClient.setQueryData<TCurrentUserInfo>(['user'], (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          childUsers: prev.childUsers.filter((x) => x.id !== id),
        };
      });
    },
  });
};

/**
 * Обновить данные дочернего пользователя
 */
export const useUpdateChildUserDataMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: TUpdateUserRequest }) => {
      return api.updateUser(id, data);
    },
    onSuccess: (response, { id }) => {
      queryClient.setQueryData<TCurrentUserInfo>(['user'], (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          childUsers: prev.childUsers.map((x) => (x.id !== id ? x : response)),
        };
      });
    },
  });
};
