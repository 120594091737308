import { useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { PaylerModal } from '@payler/ui-components';
import { CryptoPaymentsFiltersForm } from '../forms/CryptoPaymentsFilters/CryptoPaymentsFiltersForm';

const cryptoPaymentsFiltersModalOpenedAtom = atom({
  key: createRecoilKey('apmPaymentsFiltersModalOpenedAtom'),
  default: false,
});

export const useShowCryptoPaymentsFiltersModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(cryptoPaymentsFiltersModalOpenedAtom, true);
      },
    [],
  );

export const useCloseCryptoPaymentsFiltersModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(cryptoPaymentsFiltersModalOpenedAtom, false);
      },
    [],
  );

export const CryptoPaymentsFiltersModal = () => {
  const visible = useBreakpointValue({ base: true, lg: false });
  const open = useRecoilValue(cryptoPaymentsFiltersModalOpenedAtom);
  const close = useCloseCryptoPaymentsFiltersModal();
  if (!visible) return null;
  return (
    <PaylerModal close={close} open={open}>
      <CryptoPaymentsFiltersForm />
    </PaylerModal>
  );
};
