import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { PasswordInputField } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetNewPassword } from '../../hooks/user/auth';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { PASSWORD_REGEXP } from '../../const';

export type TSetNewPasswordFormProps = {
  onSubmitSuccess: () => void;
  userId: string;
  code: string;
  mode?: 'confirm' | 'reset';
};

interface FormData {
  password: string;
  confirmPassword: string;
}

function createResolver(t: TFunction) {
  return yupResolver(
    yup.object().shape({
      password: yup
        .string()
        .required(t('validate.required'))
        .min(8, t('validate.minPasswordLength'))
        .matches(PASSWORD_REGEXP, t('validate.notMatchPasswordPattern')),
      confirmPassword: yup
        .string()
        .required(t('validate.required'))
        .test((value, ctx) => {
          if (!value)
            return ctx.createError({ message: t('validate.required') });
          if (value !== ctx.parent.password)
            return ctx.createError({
              message: t('validate.doNotMatchPassword'),
            });
          if (!PASSWORD_REGEXP.test(value)) {
            return ctx.createError({
              message: t('validate.notMatchPasswordPattern'),
            });
          }
          return true;
        }),
    }),
  );
}

export const SetNewPasswordForm: React.FC<TSetNewPasswordFormProps> = ({
  onSubmitSuccess,
  userId,
  code,
  mode = 'reset',
}) => {
  const { t } = useTranslation();
  const methods = useForm<FormData>({ resolver: createResolver(t) });
  const [error, setError] = useState('');
  const getError = useGetAxiosError();
  const { mutate, isLoading } = useSetNewPassword(mode);
  const handleSubmit = methods.handleSubmit(({ password }: FormData) => {
    setError('');
    mutate(
      {
        password,
        userId,
        code: encodeURIComponent(code),
      },
      { onSuccess: onSubmitSuccess, onError: (e) => setError(getError(e)) },
    );
  });
  return (
    <Box mt={{ base: 10, sm: 16 }} w="100%">
      <FormProvider {...methods}>
        <Box
          as="form"
          onSubmit={handleSubmit}
          height="100%"
          position="relative"
        >
          <Text as="h1" textStyle={TextStyles.h1}>
            {t('auth:setNewPassword.header')}
          </Text>
          <Text
            mt={1}
            mb={5}
            textStyle={TextStyles.BodyUI16Regular}
            color="primary.400"
          >
            {t('auth:setNewPassword.description')}
          </Text>
          <VStack alignItems="stretch" spacing={3}>
            <PasswordInputField
              name="password"
              placeholder={t('auth:setNewPassword.newPassword')}
            />
            <PasswordInputField
              name="confirmPassword"
              placeholder={t('auth:setNewPassword.confirmPassword')}
            />
            {error && <ApiErrorText>{error}</ApiErrorText>}
            <Box
              display="flex"
              mt={5}
              w="100%"
              position={{ base: 'absolute', sm: 'initial' }}
              left="0"
              right="0"
              bottom={1}
            >
              <Button
                isLoading={isLoading}
                isDisabled={isLoading}
                type="submit"
                variant="primary"
                w="100%"
              >
                {t('auth:setNewPassword.submitButton')}
              </Button>
            </Box>
          </VStack>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default SetNewPasswordForm;
